import Loans from "../Loans"

function Money() {
    return (
        <>
            <h2 className="header">Lista dlugow</h2>
            <Loans />
        </>
    )
}

export default Money
